import config from '../config.json';
import { Node, ModalData } from '../rete/types';

const slugify = require('slugify');

const chatbotRegex = new RegExp(config.regex.chatbot_regex);

export const mainParams = (): [string, string] => {
    const path = window.location.pathname;
    const params = path.split('/');

    if (params.length < 3) {
        throw new Error('Invalid URL format, /<service>/@<bot_mxid>:<domain> expected');
    }

    const service = params[1];
    const bot_mxid = params[2];
    if (!chatbotRegex.test(bot_mxid)) {
        throw new Error('Invalid bot_mxid format, @<bot_mxid>:<domain> expected');
    }

    return [service, bot_mxid];
};

export const checkJinja2Syntaxis = (value: string): boolean => {
    const jinja2Regex = new RegExp(config.regex.jinja2_variable);
    return jinja2Regex.test(value);
};

export const clearMessage = (text: string): string => {
    if (text === undefined || text === '' || text === null) {
        return '';
    }
    return text.replace(/\\s/g, '');
};

export const getNextNodeName = (node_type: string, nodes?: Node[]): string => {
    const nodes_of_type = nodes!.filter((node: any) => node.label === node_type);
    const nodeName = `${node_type}_${nodes_of_type.length}`;
    return nodeName;
};

export const getFormatTextAddEmoji = (text: string, position: number, element: string) => {
    const subTextValueStart = text.substring(0, position);
    const subTextValueEnd = text.substring(position, text.length).trim();
    const formattedText = `${subTextValueStart}${element} ${subTextValueEnd}`;
    return formattedText.trim();
};

export const checkValidUrl = (url: string): boolean => {
    const urlRegex = new RegExp('^(https|http):\/\/[^\s/$.?#].[^\s]*$');
    return urlRegex.test(url);
};

export const slugify_text = (text?: string): string => {
    if (text === undefined) return '';
    return slugify(text, {
        lower: true,
        remove: /[*+~.()'"!:@]/g,
        locale: config.language,
        replacement: '_',
    });
};

export const nodeNameAlreadyExists = (name: string, node_id?: string, nodes?: Node[]): boolean => {
    if (nodes === undefined) return false;
    const newName = slugify_text(name);
    return nodes.some((node) => (
        slugify_text((node?.controls?.modal as ModalData)?.options?.modalInfo?.name) === newName
        && node.id !== node_id)
    );
};