import { ClassicPreset as Classic } from 'rete';
import { InputControl } from '../controls';
import { DiContainer } from '../types';
import { LeaveData } from '../models/Leave';
import { LeaveModalControl } from '../controls/modal/leave_modal/LeaveModal';

const socket = new Classic.Socket('socket');

declare type LeaveNodeData = {
    node_id: string;
    leave_data: LeaveData;
};
export class LeaveNode extends Classic.Node {
    width = 200;
    height = 180;
    constructor(di: DiContainer, nodeData?: LeaveNodeData) {
        super('Leave');

        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addControl(
            'node_id',
            new InputControl(
                { hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id }
            )
        );
        this.addControl('modal', new LeaveModalControl({ di, nodeId: this.id, socket, modalInfo: nodeData?.leave_data }));
    }
}