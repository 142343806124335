
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    TextField,
    Typography,
    Grid,
    IconButton,
    Button,
    Alert,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { Add, WarningAmber, Settings, Cancel, Save, ExpandMore, ExpandLess } from '@mui/icons-material';
import { DiContainer } from '../../../types';
import { Case, SwitchData } from '../../../models/Switch';
import { checkJinja2Syntaxis, getNextNodeName, nodeNameAlreadyExists } from '../../../../util/util';
import { CaseComponent } from './CaseComponent';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { reorder } from '../../../components/utils/reorderOnDragList';

declare type SwitchModalControlOptions = {
    modalInfo?: SwitchData;
    nodeId: string;
    di: DiContainer;
    socket: Classic.Socket;
};

export class SwitchModalControl extends Classic.Control {
    constructor(public options?: SwitchModalControlOptions) {
        super();
    }
}

export const SwitchModal = (props: { data: SwitchModalControl }): JSX.Element => {
    const { t } = useTranslation();
    const [switchData, setSwitchData] = useState<SwitchData>(props.data.options?.modalInfo || {
        name: getNextNodeName('Switch', props.data.options!.di.editor.getNodes()),
        validation: '',
        validation_attempts: '',
        cases: [
            { id: 'default', o_connection: '', variables: [] },
        ],
    });
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState<boolean>(false);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useMemo(() => {
        if (props.data.options?.modalInfo && !open) {
            const node_data_copy = JSON.parse(JSON.stringify(props.data.options.modalInfo));
            setSwitchData(node_data_copy);
        }
    }, [open]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleExpandClick = () => {
        setExpanded((prev) => !prev);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const checkAddConnection = (value: string): boolean => {
        // Check if the value of the connection is a jinja2 variable or the string 'finish'
        // It is used to determine if the connection should be added to the node
        return checkJinja2Syntaxis(value) || value === 'finish';
    };

    const updateOutputConnection = (): void => {
        const di = props.data.options!.di;
        const node = di.editor.getNode(props.data.options!.nodeId!);
        const socket = props.data.options!.socket;
        const outputIDs: string[] = [];
        const casesID: string[] = [];

        if (node.outputs) {
            for (const output in node.outputs) {
                outputIDs.push(output);
            }
        }

        switchData.cases.forEach((item) => {
            if (!checkAddConnection(item.o_connection)) {
                casesID.push(item.id);
            }
        });


        outputIDs.map((output) => {
            if (!casesID.includes(output)) {
                node.removeOutput(output);
            }
        });

        switchData.cases.forEach((item) => {
            if (!outputIDs.includes(item.id) && !checkAddConnection(item.o_connection)) {
                node.addOutput(item.id, new Classic.Output(socket, item.id, false));
            }
        });
        di.updateNode(props.data.options!.nodeId!);
    };

    const validateForm = (): string | undefined => {
        if (switchData.name.length > 20) {
            return t('switch.name_field_too_long');
        } else if (switchData.cases.length === 0) {
            return t('switch.you_must_have_at_least_one_case');
        } else if (nodeNameAlreadyExists(switchData.name, props.data.options?.nodeId, props.data.options?.di.editor.getNodes())) {
            return t('switch.name_already_exists');
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const error = validateForm();
        if (error) {
            showErrorMessage(error);
            return;
        }
        props.data.options!.modalInfo = {
            name: switchData.name,
            validation: switchData.validation,
            validation_attempts: switchData.validation_attempts,
            cases: switchData.cases,
        };
        props.data.options!.di.updateControl(props.data.id);

        updateOutputConnection();
        updateNodeHeigth();
        onClose();
    };

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const cases:
            Case[] = reorder(
                switchData.cases,
                result.source.index,
                result.destination.index
            );

        setSwitchData({
            ...switchData,
            cases: [...cases],
        });
    };

    const addNewCase = (): void => {
        setSwitchData({
            ...switchData,
            cases: [{ id: '', o_connection: '', variables: [] }, ...switchData.cases],
        });
    };

    const removeCase = (index: number): void => {
        const newCases = [...switchData.cases];
        newCases.splice(index, 1);
        setSwitchData({ ...switchData, cases: newCases });
    };

    const saveCase = (index: number, key: 'id' | 'o_connection', value: string): void => {
        const newCases = [...switchData.cases];
        newCases[index][key] = value;
        setSwitchData({ ...switchData, cases: newCases });
    };

    const addVariable = (caseIndex: number): void => {
        const newCases = [...switchData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.push({ key: '', value: '' });
        }
        setSwitchData({ ...switchData, cases: newCases });
    };

    const saveVariable = (index: number, caseIndex: number, key: 'key' | 'value', value: string): void => {
        const newCases = [...switchData.cases];
        if (newCases[caseIndex].variables != undefined) {
            (newCases[caseIndex].variables ?? [])[index][key] = value;
        }
        setSwitchData({ ...switchData, cases: newCases });
    };

    const removeVariable = (caseIndex: number, index: number): void => {
        const newCases = [...switchData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.splice(index, 1);
        }
        setSwitchData({ ...switchData, cases: newCases });
    };

    const updateNodeHeigth = (): void => {
        const nodeHeigth = 180 + (switchData.cases.length * 30);
        const editor_node = props.data.options!.di.editor.getNode(props.data.options!.nodeId);
        editor_node.height = nodeHeigth;
        props.data.options!.di.updateNode(props.data.options!.nodeId);
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    <Typography textAlign={'center'} variant='h6' gutterBottom>
                        {t('switch.switch_node_options')}
                        <span className='close' onClick={onClose}>
                            &times;
                        </span>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography variant="caption" display="block" gutterBottom>
                            {t('switch.required_fields')}
                        </Typography>
                        <TextField
                            label={t('switch.name')}
                            id='node-name'
                            value={switchData.name}
                            required
                            onChange={(e): void => setSwitchData({ ...switchData, name: e.target.value })}
                            sx={{ m: 1, width: '98.5%' }}
                            size="small"
                        />
                        <TextField
                            label={t('switch.validation')}
                            id='validation'
                            value={switchData.validation}
                            onChange={(e): void => setSwitchData({ ...switchData, validation: e.target.value })}
                            sx={{ m: 1, width: '60ch' }}
                            multiline
                            size="small"
                        />
                        <TextField
                            label={t('switch.validation_attempts')}
                            id='validation-attempts'
                            type='number'
                            value={switchData.validation_attempts}
                            onChange={(e): void => setSwitchData({ ...switchData, validation_attempts: e.target.value })}
                            sx={{ m: 1, width: '60ch' }}
                            size="small"
                        />
                        <Grid container spacing={0} className='cases-container' sx={{  pr: 1 }}>
                            <Grid container spacing={0} sx={{ mb: 1 }}>
                                <Grid item xs={10}>
                                    <Typography
                                        textAlign='left'
                                        variant='h6'
                                        sx={{ ml: 1, fontSize: '1.2rem' }}
                                    >
                                        {t('switch.cases')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Box className='add-case' textAlign={'end'}>
                                        <Tooltip title={t('switch.add_case')}>
                                            <IconButton
                                                aria-label='add-case'
                                                onClick={addNewCase}
                                            >
                                                <Add sx={{ fontSize: '1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={1} justifySelf={'start'}>
                                    <Box className='expand-case-option' textAlign={'start'}>
                                        <IconButton
                                            aria-label='expand-case-item'
                                            onClick={() => { handleExpandClick();}}
                                            sx={{ ml: .5 }}
                                        >
                                            {expanded && <ExpandMore sx={{ fontSize: '1rem' }} />}
                                            {!expanded && <ExpandLess sx={{ fontSize: '1rem' }} />}
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppableInputModalCases">
                                    {(provided: DroppableProvided) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {switchData.cases.map((item, index) => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{ ml: 1, pl: 2 ,pr: 2 }}
                                                    key={`components-switch-index-cases-${index}`}

                                                >
                                                    <CaseComponent
                                                        key={index}
                                                        data={item}
                                                        index={index}
                                                        removeCase={removeCase}
                                                        saveCase={saveCase}
                                                        addVariable={addVariable}
                                                        removeVariable={removeVariable}
                                                        saveVariable={saveVariable}
                                                        expanded={expanded}
                                                        textFileWidth={'45.5%'}
                                                    />
                                                </Grid>
                                            ))}
                                            {provided.placeholder}
                                        </Grid>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Grid>
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('switch.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('switch.save_changes')}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};