import { Case } from '../models/Switch';
import {
    TextField,
    Tooltip,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Box,
    Typography,
} from '@mui/material';
import {
    Remove,
    PostAdd,
    ExpandMore,
    ExpandLess,
    Check,
    Close,
    Handshake,
    Block,
    HourglassDisabled,
    Pause,
    Star,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

declare type StaticCaseVariablesComponentProps = {
    data: Case;
    index: number;
    addVariable: (caseIndex: number) => void;
    removeVariable: (caseIndex: number, index: number) => void;
    saveVariable: (index: number, caseIndex: number, key: 'key' | 'value', value: string) => void;
};

export const StaticCaseVariables = (props: StaticCaseVariablesComponentProps): JSX.Element => {
    const { t } = useTranslation();
    const [expandedVariables, setExpandedVariables] = useState(false);

    const handleCaseIcon = (): JSX.Element | undefined => {
        if (props.data.id === 'True') {
            return <Check sx={{ fontSize:'1.2rem', verticalAlign:'top' }}/>;
        } else if (props.data.id === 'False') {
            return <Close sx={{ fontSize:'1.2rem', verticalAlign:'top' }}/>;
        } else if (props.data.id === 'join'){
            return <Handshake sx={{ fontSize:'1.2rem', verticalAlign:'top' }}/>;
        } else if (props.data.id === 'reject'){
            return <Block sx={{ fontSize:'1.2rem', verticalAlign:'top' }}/>;
        } else if (props.data.id === 'timeout'){
            return <HourglassDisabled sx={{ fontSize:'1.2rem', verticalAlign:'top' }}/>;
        } else if (props.data.id === '202'){
            return <Pause sx={{ fontSize:'1.2rem', verticalAlign:'top' }}/>;
        } else if (props.data.id === 'default'){
            return <Star sx={{ fontSize:'1.2rem', verticalAlign:'top' }}/>;
        }
    };

    return (
        <div key={props.index} className='rt_content_cases'>
            <Grid container spacing={0} className="rm_margin_content">
                <Grid item xs={5} sx={{ pr: 2, mb: 1, ml:2 }}>
                    <Typography textAlign='left' variant='subtitle2'>{t(`static_case_variables.${props.data.id}`)} {handleCaseIcon()}</Typography>
                </Grid>
                <Grid item xs={6} sx={{ pr: 2, mb: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mb: 1 }}>
                        <Box className='expand-case-option' sx={{ mr: 1 }}>
                            <Tooltip title={t('case_component.add_variable')} >
                                <IconButton
                                    size='small'
                                    aria-label="add-variable"
                                    onClick={(): void => props.addVariable(props.index)}
                                >
                                    <PostAdd sx={{ fontSize: '1.2rem' }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box className='expand-case-option' display={'inline'}>
                            <IconButton
                                aria-label='expand-interactive-item'
                                onClick={() => { setExpandedVariables(!expandedVariables); }}
                            >
                                {expandedVariables && <ExpandLess sx={{ fontSize: '1rem' }} />}
                                {!expandedVariables && <ExpandMore sx={{ fontSize: '1rem' }} />}
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ pr: 2 }}>
                    <Accordion
                        className='rt_cases_accordion'
                        expanded={expandedVariables}
                    >
                        <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            {t('case_component.variables')}
                        </AccordionSummary>
                        <AccordionDetails>
                            {(props.data.variables ?? []).map((variable, index) => (
                                <div key={index}>
                                    <TextField
                                        label={t('case_component.key')}
                                        id={`variable-key-${props.index}`}
                                        value={variable.key}
                                        onChange={(e): void => props.saveVariable(index, props.index, 'key', e.target.value)}
                                        sx={{ mb: 1, width: '43%' }}
                                        size='small'
                                    />
                                    <TextField
                                        label={t('case_component.value')}
                                        id={`variable-value-${props.index}`}
                                        value={variable.value}
                                        onChange={(e): void => props.saveVariable(index, props.index, 'value', e.target.value)}
                                        sx={{ ml: 1.5, width: '43%' }}
                                        size='small'
                                    />
                                    <Box className='remove-case' sx={{ display: 'inline' }}>
                                        <Tooltip title={t('case_component.remove_variable')} >
                                            <IconButton
                                                aria-label="remove-variable"
                                                size='small'
                                                sx={{ ml: 1.5, mt: 1 }}
                                                onClick={(): void => props.removeVariable(props.index, index)}
                                            >
                                                <Remove sx={{ fontSize: '1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </div>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </div>
    );
};