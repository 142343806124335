import { useState } from 'react';
import { ClassicPreset as Classic } from 'rete';
import './Input.css';

declare type InputControlOptions = {
    readonly?: boolean;
    value?: string;
    placeholder?: string;
    hidden?: boolean;
};

export class InputControl extends Classic.Control {
    constructor(public options?: InputControlOptions) {
        super();
    }
}

export const Input = (props: { data: InputControl }): JSX.Element => {
    const [value, setValue] = useState(props.data.options?.value);
    const _setValue = (value: string): void => {
        setValue(value);
        props.data.options!.value = value;
    };

    return (
        <input
            readOnly={props.data.options?.readonly}
            value={value}
            hidden={props.data.options?.hidden}
            placeholder={props.data.options?.placeholder}
            onChange={(e): void => _setValue(e.target.value)}
            className='custom-input' />
    );
};