import { ClassicPreset as Classic } from 'rete';
import { InputControl, GptAssistantModalControl } from '../controls';
import { DiContainer } from '../types';
import { GptAssistantData } from '../models/GptAssistant';

declare type GptAssistantNodeData = {
    node_id: string;
    height?: number;
    assistant_data: GptAssistantData;
};

const socket = new Classic.Socket('socket');
export class GptAssistantNode extends Classic.Node {
    width = 200;
    height = 180;
    constructor(di: DiContainer, nodeData?: GptAssistantNodeData) {
        super('GptAssistant');

        const modalControl = new GptAssistantModalControl(
            { di: di, nodeId: this.id, socket: socket, modalInfo: nodeData?.assistant_data }
        );
        this.height = nodeData?.height || 180;
        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addControl(
            'node_id',
            new InputControl(
                { hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id }
            )
        );
        this.addControl('modal', modalControl);
    };
}