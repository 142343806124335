import { Box, Button, Grid, Typography } from '@mui/material';
import {
    InteractiveAction,
    InteractiveData,
    InteractiveItems,
    InteractiveOption,
} from '../../models/Interactive';
import { useState } from 'react';
import PreviewListOptions from './PreviewListOptions';

const PreviewLists = (
    props: {
        interactiveData: InteractiveData
        interactiveTypeMedia: string
        interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[]
    }
) => {
    const [showDiv, setShowDiv] = useState(false);

    const toggleDiv = () => {
        setShowDiv(!showDiv);
    };

    return (
        <>
            <Grid
                item
                xs={10}
                sx={{
                    mt: 1,
                    pb: 1,
                    height: '10vh',
                    maxHeight: '10vh',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                }}
                className='preview-container'
            >
                {props.interactiveData.interactive_message.title && (
                    <Typography
                        textAlign='left'
                        variant='h6'
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            color: '#444',
                        }}
                    >
                        {props.interactiveData.interactive_message.title}
                    </Typography>
                )}
                {props.interactiveData.interactive_message.body && (
                    <Typography
                        textAlign='left'
                        variant='h6'
                        sx={{
                            fontSize: '1rem',
                            color: '#444',
                        }}
                    >
                        {props.interactiveData.interactive_message.body as string}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={10} className='list-container'>
                <Grid
                    container
                    spacing={1}
                    sx={{ mb: 2 }}
                >
                    {
                        props.interactiveData.interactive_message.global_buttons &&
                        props.interactiveData.interactive_message.global_buttons.map((button, index) => {
                            if (!button.title) return <></>;
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    key={`global-button-${index}`}
                                >
                                    <Button
                                        className='button-preview'
                                        variant='contained'
                                        color='primary'
                                        onClick={toggleDiv}
                                        fullWidth
                                    >
                                        {button.title as string}
                                    </Button>
                                </Grid>
                            );
                        })
                    }

                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{ pr: 2, height: '45vh' }}
                className='container-list-preview'
            >
                <Grid
                    container
                    spacing={1}
                    className={`slide-div ${showDiv ? 'visible' : ''}`}
                    sx={{
                        height: '100%',
                        pl: 1,
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            height: '100%',
                        }}
                        className='slide-div-list-container'
                    >
                        {
                            props.interactiveData.interactive_message.global_buttons &&
                            props.interactiveData.interactive_message.global_buttons.map((button, index) => {
                                if (!button.title) return <></>;
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        key={`text-global-button-${index}`}
                                        sx={{ mb: 1.5 }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'initial',
                                            }}
                                        >
                                            <span className='close' onClick={toggleDiv}>
                                                &times;
                                            </span>
                                            <Typography
                                                textAlign='center'
                                                variant='h6'
                                                sx={{
                                                    fontSize: '1rem',
                                                    color: '#444',
                                                    width: '86%',
                                                }}
                                            >
                                                {button.title as string}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                );
                            })
                        }
                        <PreviewListOptions
                            interactiveData={props.interactiveData}
                            interactiveOptionsValues={props.interactiveOptionsValues}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PreviewLists;