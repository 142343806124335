import { ClassicPreset as Classic } from 'rete';
import { InputControl, DistributeChatModalControl } from '../controls';
import { DiContainer } from '../types';
import { DistributeChatData } from '../models/DistributeChat';

const socket = new Classic.Socket('socket');

declare type DistributeChatNodeData = {
    node_id: string;
    nodeData?: DistributeChatData;
};

export class DistributeChatNode extends Classic.Node {
    width = 200;
    height = 180;
    constructor(di: DiContainer, nodeData?: DistributeChatNodeData) {
        super('Distribute Chat');

        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addOutput('enqueued', new Classic.Output(socket, 'enqueued', false));
        this.addOutput('default', new Classic.Output(socket, 'default', false));
        this.addControl(
            'node_id',
            new InputControl(
                { hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id }
            )
        );
        this.addControl(
            'modal',
            new DistributeChatModalControl(
                { di, nodeId: this.id, socket, modalInfo: nodeData?.nodeData }
            )
        );
    }
}