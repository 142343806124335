import { useEffect, useState } from 'react';
import { InteractiveAction, InteractiveData, InteractiveItems, InteractiveOption } from '../../models/Interactive';
import {
    Box,
    Grid,
    Typography,
} from '@mui/material';

const PreviewButtons = (
    props: {
        interactiveData: InteractiveData
        interactiveTypeMedia: string
        interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[]
    }
) => {
    const [gridSize, setGridSize] = useState(props.interactiveOptionsValues.length < 3 ? 6 : 12);

    useEffect(() => {
        if(!props.interactiveOptionsValues.length) return;

        if(props.interactiveOptionsValues.length < 3){
            setGridSize(6);
        } else {
            setGridSize(12);
        }
    }, [props.interactiveOptionsValues]);

    return (
        <>
            <Grid
                item
                xs={10}
                sx={{
                    mt: 1,
                    pb: 1,
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                }}
                className='preview-container'
            >
                {
                    props.interactiveTypeMedia === 'image' &&
                    props.interactiveData.interactive_message.content?.url && (
                        <img
                            src={props.interactiveData.interactive_message.content?.url}
                            style={{
                                width: '98%',
                                height: 'auto',
                                borderRadius: '8px',
                            }}
                        />
                    )
                }
                {
                    props.interactiveTypeMedia === 'video' &&
                    props.interactiveData.interactive_message.content?.url && (
                        <video
                            src={props.interactiveData.interactive_message.content?.url}
                            style={{
                                width: '98%',
                                height: 'auto',
                                borderRadius: '8px',
                            }}
                            controls
                        />
                    )
                }
                {
                    props.interactiveTypeMedia === 'document' &&
                    props.interactiveData.interactive_message.content?.url && (
                        <Box
                            className='document-preview'
                        >
                            <img
                                src={'/pdf-image.webp'}
                                style={{
                                    height: '100%',
                                    borderRadius: '8px',
                                }}
                            />
                        </Box>
                    )
                }
                {props.interactiveData.interactive_message.content?.header && (
                    <Typography
                        textAlign='left'
                        variant='h6'
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            color: '#444',
                        }}
                    >
                        {props.interactiveData.interactive_message.content?.header}
                    </Typography>
                )}
                {props.interactiveData.interactive_message.content?.text && (
                    <Typography
                        textAlign='left'
                        variant='body1'
                        sx={{
                            fontSize: '0.9rem',
                            color: '#444',
                            mb: .5,
                        }}
                    >
                        {props.interactiveData.interactive_message.content?.text}
                    </Typography>
                )}
                {props.interactiveData.interactive_message.content?.caption && ((
                    <Typography
                        textAlign='left'
                        variant='body2'
                        sx={{
                            fontSize: '0.85rem',
                            color: '#777',
                        }}
                    >
                        {props.interactiveData.interactive_message.content?.caption}
                    </Typography>
                ))}
            </Grid>
            <Grid
                item
                xs={10}
                className='button-container'
            >
                <Grid
                    container
                    spacing={1}
                    sx={{ mb: 2 }}
                >
                    {
                        props.interactiveOptionsValues &&
                        props.interactiveOptionsValues.length > 0 && (
                            props.interactiveOptionsValues.map((option, index) => {
                                if ((option as InteractiveOption).title) {
                                    return (
                                        <Grid
                                            item
                                            xs={gridSize}
                                            key={`button-${index}`}
                                        >
                                            <Box
                                                className='button-preview'
                                            >
                                                {(option as InteractiveOption).title}
                                            </Box>
                                        </Grid>
                                    );
                                }
                                else return <></>;
                            })
                        )
                    }
                </Grid>
            </Grid>
        </>
    );
};

export default PreviewButtons;