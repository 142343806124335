
import React, { useState, useEffect, useRef } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    Button,
    Typography,
    Alert,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { WarningAmber, Settings, Cancel, Save } from '@mui/icons-material';
import { DiContainer } from '../../../types';
import AceEditor from 'react-ace';
import { HttpRequestData } from '../../../models/HttpRequest';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-solarized_light';
import { checkJinja2Syntaxis, nodeNameAlreadyExists } from '../../../../util/util';
import { useTranslation } from 'react-i18next';

declare type HttpRequestModalControlOptions = {
    modalInfo?: HttpRequestData;
    nodeId: string;
    di: DiContainer;
    socket: Classic.Socket;
};

export class HttpRequestModalControl extends Classic.Control {
    constructor(public options?: HttpRequestModalControlOptions) {
        super();
    }
}

export const HttpRequestModal = (props: { data: HttpRequestModalControl }): JSX.Element => {
    const { t } = useTranslation();
    const [httpRequestData, setHttpRequestData] = useState('{}');
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useEffect(() => {
        if (props.data.options?.modalInfo && !open) {
            setHttpRequestData(JSON.stringify(props.data.options.modalInfo, null, 2));
        }
    }, [open, httpRequestData]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const checkAddConnection = (value: string): boolean => {
        // Check if the value of the connection is a jinja2 variable or the string 'finish'
        // It is used to determine if the connection should be added to the node
        return checkJinja2Syntaxis(value) || value === 'finish';
    };

    const updateOutputConnection = (): void => {
        const di = props.data.options!.di;
        const node = di.editor.getNode(props.data.options!.nodeId!);
        const socket = props.data.options!.socket;
        const outputIDs: string[] = [];
        const casesID: string[] = [];
        const node_data: HttpRequestData = props.data.options!.modalInfo!;

        if (node.outputs) {
            for (const output in node.outputs) {
                outputIDs.push(output);
            }
        }

        node_data.cases.forEach((item) => {
            if (!checkAddConnection(item.o_connection)) {
                casesID.push(item.id || item.case || '');
            }
        });

        outputIDs.map((output) => {
            if (!casesID.includes(output)) {
                node.removeOutput(output);
            }
        });

        node_data.cases.forEach((item) => {
            if (
                !outputIDs.includes(item.id || item.case || '') &&
                !checkAddConnection(item.o_connection)
            ) {
                const connection_id = item.id || item.case || '';
                node.addOutput(connection_id, new Classic.Output(socket, connection_id, false));
            }
        });
        di.updateNode(props.data.options!.nodeId!);
    };

    const updateNodeHeigth = (): void => {
        const nodeData = props.data.options!.modalInfo!;
        const nodeHeigth = 180 + (nodeData.cases.length * 30);
        const editor_node = props.data.options!.di.editor.getNode(props.data.options!.nodeId);
        editor_node.height = nodeHeigth;
        props.data.options!.di.updateNode(props.data.options!.nodeId);
    };
    
    const validateForm = (): string | undefined => {
        if (!httpRequestData) {
            return t('http_request.http_data_can_not_be_empty');
        } else if (!JSON.parse(httpRequestData).name) {
            return t('http_request.name_field_is_required');
        } else if (JSON.parse(httpRequestData).name.length > 20) {
            return t('http_request.name_field_too_long');
        } else if (nodeNameAlreadyExists(JSON.parse(httpRequestData).name, props.data.options?.nodeId, props.data.options?.di.editor.getNodes())) {
            return t('http_request.name_already_exists');
        }

        return;
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        try {
            const errorMessage = validateForm();
            if (errorMessage) {
                showErrorMessage(errorMessage);
                return;
            };

            props.data.options!.modalInfo = JSON.parse(httpRequestData);
        } catch (error) {
            showErrorMessage(t('http_request.invalid_json_format'));
            return;
        }
        updateOutputConnection();
        updateNodeHeigth();
        onClose();
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={'md'}
                fullWidth={true}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    <Typography textAlign={'center'} variant='h6' gutterBottom>
                        {t('http_request.http_request_node_options')}
                        <span className='close' onClick={onClose}>
                            &times;
                        </span>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <AceEditor
                            placeholder={t('http_request.placeholder_text')}
                            mode="json"
                            theme="solarized_light"
                            name="blah2"
                            onChange={(value): void => setHttpRequestData(value)}
                            fontSize={16}
                            lineHeight={22}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            value={httpRequestData}
                            width={'100%'}
                            setOptions={{
                                useWorker: false,
                                enableBasicAutocompletion: false,
                                enableLiveAutocompletion: true,
                                enableSnippets: false,
                                showLineNumbers: true,
                                tabSize: 2,
                            }}
                        />
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('http_request.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('http_request.save_changes')}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};