import { mainParams, checkJinja2Syntaxis } from '../util/util';
import { FlowService } from '../services/flow';
import {
    MessageNode,
    InputNode,
    InviteUserNode,
    LeaveNode,
    DelayNode,
    CheckTimeNode,
    LocationNode,
    MediaNode,
    SetVarsNode,
    SubroutineNode,
    SwitchNode,
    DistributeChatNode,
    StartNode,
    HttpRequestNode,
    EmailNode,
    GptAssistantNode,
    InteractiveNode,
    StartSubNode,
} from './nodes';
import { DiContainer, Node } from './types';
import { ExportMessageNode } from './models/Message';
import { ExportInputNode } from './models/Input';
import { ExportInviteUserNode } from './models/InviteUser';
import { ClassicPreset as Classic } from 'rete';
import { InputControl } from './controls';
import { ExportLeaveNode } from './models/Leave';
import { ExportDelayNode } from './models/Delay';
import { ExportCheckTimeNode, toRange } from './models/CheckTime';
import { ExportLocationNode } from './models/Location';
import { ExportMediaData } from './models/Media';
import { ExportSetVarsNode, SetVar } from './models/SetVars';
import { ExportSubroutineNode } from './models/Subroutine';
import { ExportSwitchNode, ExportCase, Variable, Case } from './models/Switch';
import { ExportHttpRequestNode } from './models/HttpRequest';
import { ExportDistributeChatJsonData } from './models/DistributeChat';
import { ExportEmailNode } from './models/Email';
import { ExportGptAssistantNode } from './models/GptAssistant';
import { ExportInteractiveNode } from './models/Interactive';

const [menuflowDomain, botMxid] = mainParams();
const flowService = new FlowService(menuflowDomain, botMxid);

type NodeData = (
    ExportMessageNode &
    ExportInputNode &
    ExportInviteUserNode &
    ExportLeaveNode &
    ExportDelayNode &
    ExportCheckTimeNode &
    ExportLocationNode &
    ExportMediaData &
    ExportSetVarsNode &
    ExportSubroutineNode &
    ExportSwitchNode &
    ExportHttpRequestNode &
    ExportEmailNode &
    ExportGptAssistantNode &
    ExportInteractiveNode
);

const addNodeConnections = (node: Node, cases: ExportCase[]): void => {
    for (const inputCase of cases) {
        if (
            inputCase.id === 'default' ||
            checkJinja2Syntaxis(inputCase.o_connection) ||
            inputCase.o_connection === 'finish'
        ) {
            continue;
        }

        const caseIdentifier = inputCase.id || inputCase.case || '';
        node.addOutput(
            caseIdentifier, new Classic.Output(new Classic.Socket('socket'), caseIdentifier, false)
        );
    }
};

const serialize_variables = (variables: object): Variable[] => {
    const vars: Variable[] = [];
    for (const key in variables) {
        if (variables.hasOwnProperty(key)) {
            vars.push({
                key: key,
                value: (variables as Record<string, string>)[key],
            });
        }
    }
    return vars;
};

const serializeCases = (cases: ExportCase[]): Case[] => {
    return cases.map((c: ExportCase) => {
        return {
            id: c.id || c.case || '',
            o_connection: checkJinja2Syntaxis(c.o_connection) ? c.o_connection : '',
            variables: serialize_variables(c.variables || []),
        };
    });
};

const newNode = (type: string, node: NodeData, di: DiContainer): Node | null => {
    let newNode: Node | null = null;
    if (type === 'message' && node.id === 'start') {
        newNode = new StartNode();
    } else if (type === 'message' && node.subtype === 'start_sub') {
        const data = {
            node_id: node.id,
            startsub_data: {
                name: node.name,
            },
        };
        newNode = new StartSubNode(di, data);
    } else if (type === 'message') {
        const data = {
            node_id: node.id,
            message_type: node.message_type,
            nodeData: { name:node.name, text: node.text, message_type: node.message_type},
        };
        newNode = new MessageNode(di, data);
    } else if (type === 'input') {
        const node_height = 180 + (node.cases.length * 30);
        const data = {
            node_id: node.id,
            height: node_height,
            input_data: {
                name: node.name,
                text: node.text,
                variable: node.variable,
                validation: node.validation || '',
                input_type: node.input_type,
                validation_attempts: node.validation_attempts?.toString() || '',
                middlewares: node.middlewares || [],
                inactivity_options: {
                    chat_timeout: node.inactivity_options?.chat_timeout,
                    warning_message: node.inactivity_options?.warning_message,
                    time_between_attempts: node.inactivity_options?.time_between_attempts,
                    attempts: node.inactivity_options?.attempts,
                },
                cases: serializeCases(node.cases),
            },
        };

        newNode = new InputNode(di, data);
        addNodeConnections(newNode, node.cases);
    } else if (type === 'invite_user') {
        const data = {
            node_id: node.id,
            input_data: {
                name: node.name,
                timeout: node.timeout.toString(),
                invitee: node.invitee,
                cases: serializeCases(node.cases),
            },
        };
        newNode = new InviteUserNode(di, data);
    } else if (type === 'leave') {
        const data = {
            node_id: node.id,
            leave_data: {
                name: node.name,
                reason: node.reason,
            },
        };
        newNode = new LeaveNode(di, data);
    } else if (type === 'delay') {
        const data = {
            node_id: node.id,
            delay_data: {
                name: node.name,
                time: node.time,
            },
        };
        newNode = new DelayNode(di, data);
    } else if (type === 'check_time') {
        const data = {
            node_id: node.id,
            check_time_data: {
                name: node.name,
                timezone: node.timezone,
                time_ranges: node.time_ranges.map((time: string) => toRange(time)) || [],
                days_of_week: node.days_of_week.map((day: string) => toRange(day)) || [],
                days_of_month: node.days_of_month.map((day: string) => toRange(day)) || [],
                months: node.months.map((month: string) => toRange(month)) || [],
                cases: serializeCases(node.cases),
            },
        };
        newNode = new CheckTimeNode(di, data);
    } else if (type === 'location') {
        const data = {
            node_id: node.id,
            location_data: {
                name: node.name,
                latitude: node.latitude,
                longitude: node.longitude,
            },
        };

        newNode = new LocationNode(di, data);
    } else if (type === 'media') {
        const data = {
            node_id: node.id,
            media_node_data: {
                name: node.name,
                message_type: node.message_type,
                text: node.text,
                url: node.url,
                info: {
                    mimetype: node.info?.mimetype,
                    size: node.info?.size?.toString(),
                    height: node.info?.height?.toString(),
                    width: node.info?.width?.toString(),
                },
            },
        };

        newNode = new MediaNode(di, data);
    } else if (type === 'set_vars') {
        const set: SetVar[] = [];
        for (const key in node.variables?.set) {
            if (node.variables?.set.hasOwnProperty(key)) {
                set.push({
                    name: key,
                    value: (node.variables?.set as Record<string, string>)[key],
                });
            }
        }
        const data = {
            node_id: node.id,
            set_vars_data: {
                name: node.name,
                set: set || [],
                unset: node.variables?.unset || [],
            },
        };
        newNode = new SetVarsNode(di, data);
    } else if (type === 'subroutine') {
        const data = {
            node_id: node.id,
            subroutine_data: {
                name: node.name,
                go_sub: node.go_sub,
            },
        };
        newNode = new SubroutineNode(di, data);
    } else if (type === 'switch') {
        const node_height = 180 + (node.cases.length * 30);
        const data = {
            node_id: node.id,
            height: node_height,
            switch_node_data: {
                name: node.name,
                validation: node.validation || '',
                validation_attempts: node.validation_attempts?.toString() || '',
                cases: serializeCases(node.cases),
            },
        };
        newNode = new SwitchNode(di, data);
        addNodeConnections(newNode, node.cases);
    } else if (type === 'http_request' && node.subtype === 'distribute_chat') {
        const data = {
            name: (node as ExportHttpRequestNode).name,
            destination: (node.json as ExportDistributeChatJsonData).destination,
            joined_message: (node.json as ExportDistributeChatJsonData).joined_message,
            put_enqueued_portal: (node.json as ExportDistributeChatJsonData).put_enqueued_portal,
            force_distribution: (node.json as ExportDistributeChatJsonData).force_distribution,
            queue_timeout: Number((node.json as ExportDistributeChatJsonData).queue_timeout),
            campaign: (node.json as ExportDistributeChatJsonData).campaign,
            subcampaign: (node.json as ExportDistributeChatJsonData).subcampaign,
            cases: serializeCases((node as ExportHttpRequestNode).cases).filter((c: Case) => c.id !== '200'),
        };
        newNode = new DistributeChatNode(di, { node_id: node.id, nodeData: data });
    } else if (type === 'http_request') {
        const node_height = 180 + (node.cases.length * 30);
        newNode = new HttpRequestNode(di, { node_id: node.id, height: node_height, nodeData: node });
        addNodeConnections(newNode, node.cases);
    } else if (type === 'email'){
        const data = {
            node_id: node.id,
            nodeData: {
                name: node.name,
                type: node.type,
                server_id: node.server_id,
                subject: node.subject,
                recipients: node.recipients,
                attachments: node.attachments || [],
                text: node.text,
                format: node.format,
                encode_type: node.encode_type,
            },
        };
        newNode = new EmailNode(di, data);
    } else if (type === 'gpt_assistant') {
        const node_height = 180 + (node.cases.length * 30);
        const data = {
            node_id: node.id,
            height: node_height,
            assistant_data: {
                name: node.name,
                assistant_id: node.assistant_id,
                api_key: node.api_key,
                instructions: node.instructions,
                initial_info: node.initial_info,
                variable: node.variable,
                validation: node.validation || '',
                validation_attempts: node.validation_attempts?.toString() || '',
                inactivity_options: {
                    chat_timeout: node.inactivity_options?.chat_timeout,
                    warning_message: node.inactivity_options?.warning_message,
                    time_between_attempts: node.inactivity_options?.time_between_attempts,
                    attempts: node.inactivity_options?.attempts,
                },
                cases: serializeCases(node.cases),
            },
        };

        newNode = new GptAssistantNode(di, data);
        addNodeConnections(newNode, node.cases);
    } else if (type === 'interactive_input') {
        const node_height = 180 + (node.cases.length * 30);
        const data = {
            node_id: node.id,
            height: node_height,
            interactive_data: {
                name: node.name,
                variable: node.variable,
                validation: node.validation || '',
                validation_attempts: node.validation_attempts?.toString() || '',
                inactivity_options: {
                    chat_timeout: node.inactivity_options?.chat_timeout,
                    warning_message: node.inactivity_options?.warning_message,
                    time_between_attempts: node.inactivity_options?.time_between_attempts,
                    attempts: node.inactivity_options?.attempts,
                },
                interactive_message: node.interactive_message,
                cases: serializeCases(node.cases),
            },
        };

        newNode = new InteractiveNode(di, data);
        addNodeConnections(newNode, node.cases);
    }
    return newNode;
};

const getConnectionSourceAndTarget = (node_id: string, o_connection: string, nodes_displayed: Node[]): [Node | undefined, Node | undefined] => {
    const source_node = nodes_displayed.find(
        n => (n.controls!.node_id as InputControl)?.options?.value === node_id
    );
    const target_node = nodes_displayed.find(
        n => (n.controls!.node_id as InputControl)?.options?.value === o_connection
    );

    return [source_node, target_node];
};

export const loadFlow = async (di: DiContainer): Promise<void> => {
    const [flow, status] = await flowService.getFlow();
    if (status !== 200) {
        console.error('Error loading flow');
        return;
    }

    for (const node of flow.flow.menu.nodes as NodeData[]) {
        const type = node.type;
        const newNodeInstance = newNode(type, node, di);
        if (newNodeInstance) {
            await di.editor.addNode(newNodeInstance);
        }
    }

    const nodes_displayed = di.editor.getNodes();
    for (const node of flow.flow.menu.nodes as NodeData[]) {
        if (!node.o_connection && !node.cases) {
            continue;
        }

        if (node.o_connection) {
            const [source_node, target_node] = getConnectionSourceAndTarget(node.id, node.o_connection, nodes_displayed);
            await di.editor.addConnection(new Classic.Connection(source_node!, 'o_connection', target_node!, 'input'));
        } else if (node.cases) {
            for (const nodeCase of node.cases) {
                if (checkJinja2Syntaxis(nodeCase.o_connection) || nodeCase.o_connection === 'finish') {
                    continue;
                }
                const [source_node, target_node] = getConnectionSourceAndTarget(node.id, nodeCase.o_connection, nodes_displayed);
                if (!source_node || !target_node) {
                    continue;
                }

                let nodeCaseId = nodeCase.id || nodeCase.case || '';
                if (nodeCaseId === '202' && node.type === 'http_request' && node.subtype === 'distribute_chat') nodeCaseId = 'enqueued';
                await di.editor.addConnection(new Classic.Connection(source_node!, nodeCaseId, target_node!, 'input'));
            }
        }
    }
};