import { useState } from 'react';
import { ClassicPreset as Classic } from 'rete';
import './TextArea.css';

declare type TextAreaControlOptions<T> = {
    readonly?: boolean;
    value?: T;
    placeholder?: string;
};

export class TextAreaControl extends Classic.Control {
    constructor(public options?: TextAreaControlOptions<string>) {
        super();
    }
}

export function TextArea(props: { data: TextAreaControl }): JSX.Element {
    const [textAreaValue, setTextAreaValue] = useState(props.data.options?.value);

    const _setValue = (value: string): void => {
        setTextAreaValue(value);
        props.data.options!.value = value;
    };

    return (
        <textarea
            readOnly={props.data.options?.readonly}
            value={textAreaValue}
            rows={3}
            placeholder={props.data.options?.placeholder}
            onChange={(e): void => _setValue(e.target.value)}
            className='custom-textarea' />
    );
}