import config from '../config.json';

export type Middleware = {
    id: string;
    type: string;
}

export class FlowUtils {
    base_url: string;

    constructor(base_url: string) {
        this.base_url = config.menuflow.base_url.replace('<domain>', base_url);
    }

    getEmailServers = async (): Promise<[{ email_servers: string[] }, number]> => {
        const url = `${this.base_url}/v1/mis/email_servers`;
        try {
            const response = await fetch(`${url}`);
            const json: { email_servers: string[] } = await response.json();
            const status: number = response.status;
            return [json, status];
        } catch (error) {
            console.error(error);
            return [{ email_servers: [] }, 400];
        }
    };

    getMiddlewares = async (): Promise<[Middleware[], number]> => {
        const url = `${this.base_url}/v1/mis/middlewares`;
        try {
            const response = await fetch(`${url}`);
            const json: { middlewares: Middleware[] } = await response.json();
            const status: number = response.status;
            return [json.middlewares, status];
        } catch (error) {
            console.error(error);
            return [[], 400];
        }
    };
}