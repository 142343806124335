import React, { useState } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { InteractiveAction, InteractiveItems, InteractiveOption } from '../../models/Interactive';
import {
    IconButton,
    TextField,
    Grid,
    Tooltip,
} from '@mui/material';
import { Dehaze, Remove } from '@mui/icons-material';
import { t } from 'i18next';
import EmojiPickerComponent, {alignButton} from '../EmojiPcker';
import { getFormatTextAddEmoji } from '../../../util/util';

const InteractiveButtons = (
    props: {
        index: number,
        item: InteractiveOption | InteractiveItems | InteractiveAction,
        interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[],
        setInteractiveOptionsValues: (interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[]) => void,
    }
) => {

    const [messageInput, setMessageInput] = useState<HTMLInputElement|null>(null);

    const removeInteractiveOption = (index: number): void => {
        if (props.interactiveOptionsValues.length > 1) {
            const newInteractiveOptionsValues = [...props.interactiveOptionsValues];
            newInteractiveOptionsValues.splice(index, 1);
            props.setInteractiveOptionsValues(newInteractiveOptionsValues as InteractiveOption[]);
        }
    };

    const handleChangeOption = (value: string) => {
        const newInteractiveOptionsValues = [...props.interactiveOptionsValues];
        (newInteractiveOptionsValues[props.index] as InteractiveOption).title = value;
        props.setInteractiveOptionsValues(newInteractiveOptionsValues as InteractiveOption[]);
    };

    const addEmoji = (emoji: string): void => {
        //add emoji to the message
        const templateInput = messageInput?.id;
        const text = document.getElementById(templateInput || '') as HTMLTextAreaElement;
        // value of the message
        const textValue = text.value;
        // cursor position
        const cursorPosition = text.selectionStart;
        text.value = getFormatTextAddEmoji(textValue, cursorPosition, emoji);
        handleChangeOption(text.value);
    };

    const getMessageInput = (input: HTMLInputElement | null) => {
        if (input) {
            setMessageInput(input);
        }
    };

    return (
        <Draggable
            draggableId={`button-${props.index}`}
            index={props.index}
            key={`interactive-option-${props.index}`}
        >
            {(provided: DraggableProvided) => (
                <Grid
                    container
                    spacing={2}
                    sx={{ mb: 1.5, ml: 1 }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <Grid item xs={1} sx={{ alignSelf: 'center' }}>
                        <Tooltip title={t('interactive.tooltip_move_button')}>
                            <IconButton
                                aria-label='move-interactive-option'
                                sx={{ ml: 2 }}
                                size='small'
                                {...provided.dragHandleProps}
                            >
                                <Dehaze sx={{ fontSize: '1rem' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={9} sx={{ alignSelf: 'center' }}>
                        <TextField
                            label={t('interactive.title')}
                            id={`interactive-title-buttons-${props.index}`}
                            value={(props.item as InteractiveOption).title ?? ''}
                            onChange={(e): void => handleChangeOption(e.target.value)}
                            multiline
                            inputRef={getMessageInput}
                            required
                            sx={{ width: '90%' }}
                            size="small"
                            inputProps={{ maxLength: 20 }}
                        />
                        <EmojiPickerComponent onEmojiSelect={(e:string) => addEmoji(e)} align={alignButton.bottom}/>
                    </Grid>
                    <Grid item xs={1} className='remove-interactive-option' sx={{ alignSelf: 'center' }}>
                        <Tooltip title={t('interactive.tooltip_remove_button')}>
                            <IconButton
                                aria-label='remove-interactive-option'
                                onClick={(): void => { removeInteractiveOption(props.index); }}
                                size='small'
                            >
                                <Remove sx={{ fontSize: '1rem' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            )}
        </Draggable>
    );
};

export default InteractiveButtons;