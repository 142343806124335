import React, { useState, useEffect } from 'react';
import {
    Typography,
    IconButton,
    TextField,
    MenuItem,
    Grid,
    Switch,
    Box,
    Alert,
    Tooltip,
} from '@mui/material';
import { Add, ExpandLess, ExpandMore, Help, WarningAmber } from '@mui/icons-material';
import { t } from 'i18next';
import { CaseComponent } from '../../controls/modal/switch_modal/CaseComponent';
import InteractiveFormMessage from './InteractiveFormMessage';
import { InteractiveAction, InteractiveData, InteractiveItems, InteractiveOption } from '../../models/Interactive';
import EmojiPickerComponent, {alignButton} from '../EmojiPcker';
import { getFormatTextAddEmoji } from '../../../util/util';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { reorder } from '../utils/reorderOnDragList';
import { Case } from '../../models/Switch';

const InteractiveForm = (
    props: {
        interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[],
        bridgeOption: string,
        interactiveTypeOption: string,
        interactiveData: InteractiveData,
        interactiveTypeMedia: string,
        setInteractiveOptionsValues: (interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[]) => void,
        setInteractiveData: (interactiveData: InteractiveData) => void,
        setInteractiveTypeMedia: (interactiveTypeMedia: string) => void,
        setBridgeOption: (bridgeOption: string) => void,
        setInteractiveTypeOption: (interactiveTypeOption: string) => void,
        enableInactivityOptions: boolean,
        setEnableInactivityOptions: (enableInactivityOptions: boolean) => void,
        formErrorMessage: string,
        formErrorVisible: boolean,
    }
) => {

    const [firstRender, setFirstRender] = useState(true);
    const [messageInput, setMessageInput] = useState<HTMLInputElement|null>(null);

    const handleChangeOption = (value: string) => {
        props.setInteractiveData(
            {
                ...props.interactiveData,
                inactivity_options:
                    { ...props.interactiveData.inactivity_options, warning_message: value },
            }
        );
    };

    const addEmoji = (emoji: string): void => {
        //add emoji to the message
        const templateInput = messageInput?.id;
        const text = document.getElementById(templateInput || '') as HTMLTextAreaElement;
        // value of the message
        const textValue = text.value;
        // cursor position
        const cursorPosition = text.selectionStart;
        text.value = getFormatTextAddEmoji(textValue, cursorPosition, emoji);
        handleChangeOption(text.value);
    };

    const getMessageInput = (input: HTMLInputElement | null) => {
        if (input) {
            setMessageInput(input);
        }
    };
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (props.interactiveOptionsValues.length > 0 && !firstRender) {
            resetInteractiveOptionsValues();
        }

        if (firstRender) {
            setFirstRender(false);
        }
    }, [props.bridgeOption, props.interactiveTypeOption]);

    const resetInteractiveOptionsValues = (): void => {
        if (props.bridgeOption === 'gupshup') {
            if (props.interactiveTypeOption === 'button') {
                props.setInteractiveOptionsValues([{
                    type: 'text',
                    title: '',
                }]);

                props.setInteractiveData({
                    ...props.interactiveData,
                    interactive_message: {
                        type: 'quick_reply',
                        content: {
                            url: '',
                            type: '',
                            header: '',
                            text: '',
                            caption: '',
                        },
                    },
                });
            }
            else {
                props.setInteractiveOptionsValues([{
                    title: '',
                    subtitle: '',
                    options: [{
                        type: 'text',
                        title: '',
                        description: '',
                        postback_text: '',
                    }],
                }]);

                props.setInteractiveData({
                    ...props.interactiveData,
                    interactive_message: {
                        type: 'list',
                        title: '',
                        body: '',
                        msgid: 'list1',
                        global_buttons: [{
                            type: '',
                            title: '',
                        }],
                    },
                });
            }
        } else {
            props.setInteractiveOptionsValues([{
                type: '',
                title: '',
                reply: { id: '', title: '' },
                description: '',
                postback_text: '',
            }]);
        }
    };

    const removeCase = (index: number): void => {
        const newCases = [...props.interactiveData.cases];
        newCases.splice(index, 1);
        props.setInteractiveData({ ...props.interactiveData, cases: newCases });
    };

    const saveCase = (index: number, key: 'id' | 'o_connection', value: string): void => {
        const newCases = [...props.interactiveData.cases];
        newCases[index][key] = value;
        props.setInteractiveData({ ...props.interactiveData, cases: newCases });
    };

    const addVariable = (caseIndex: number): void => {
        const newCases = [...props.interactiveData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.push({ key: '', value: '' });
        }
        props.setInteractiveData({ ...props.interactiveData, cases: newCases });
    };

    const saveVariable = (index: number, caseIndex: number, key: 'key' | 'value', value: string): void => {
        const newCases = [...props.interactiveData.cases];
        if (newCases[caseIndex].variables != undefined) {
            (newCases[caseIndex].variables ?? [])[index][key] = value;
        }
        props.setInteractiveData({ ...props.interactiveData, cases: newCases });
    };

    const removeVariable = (caseIndex: number, index: number): void => {
        const newCases = [...props.interactiveData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.splice(index, 1);
        }
        props.setInteractiveData({ ...props.interactiveData, cases: newCases });
    };

    const addNewCase = (): void => {
        props.setInteractiveData({
            ...props.interactiveData,
            cases: [...props.interactiveData.cases, { id: '', o_connection: '', variables: [] }],
        });
    };

    const handleExpandClick = () => {
        setExpanded((prev) => !prev);
    };

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const cases:
            Case[] = reorder(
                props.interactiveData.cases,
                result.source.index,
                result.destination.index
            );

        props.setInteractiveData({
            ...props.interactiveData,
            cases: [...cases],
        });
    };

    return (
        <>
            <Grid container spacing={0} className='interactive-container'>
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        label={t('interactive.name')}
                        id='node-name'
                        value={props.interactiveData.name}
                        required
                        onChange={
                            (e): void => props.setInteractiveData({
                                ...props.interactiveData, name: e.target.value,
                            })
                        }
                        sx={{ m: 1, width: '98%' }}
                        size="small"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={t('interactive.bridge_option')}
                        id='interactive-bridge-option'
                        value={props.bridgeOption ?? ''}
                        onChange={(e): void => {
                            props.setBridgeOption(e.target.value);
                        }}
                        sx={{ m: 1, width: '96%' }}
                        required
                        multiline
                        size="small"
                        select
                        fullWidth
                    >
                        <MenuItem key={'gupshupMenuItem'} value='gupshup'>
                            Gupshup
                        </MenuItem>
                        <MenuItem key={'whatsaaCloudMenuItem'} value='whatsapp-cloud'>
                            Whatsapp Cloud
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={t('interactive.type_option')}
                        id='interactive-type-option-form'
                        sx={{ mt: 1, ml: 1.2, width: '95%' }}
                        value={props.interactiveTypeOption ?? ''}
                        onChange={(e): void => {
                            props.setInteractiveTypeOption(e.target.value);
                        }}
                        required
                        multiline
                        size="small"
                        select
                        fullWidth
                    >
                        <MenuItem key={'gupshupMenuItem'} value='button'>
                            {t('interactive.button')}
                        </MenuItem>
                        <MenuItem key={'whatsaaCloudMenuItem'} value='list'>
                            {t('interactive.list')}
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={11}>
                        <TextField
                            label={t('interactive.variable')}
                            id="validation"
                            value={props.interactiveData.variable ?? ''}
                            onChange={(e): void => props.setInteractiveData({ ...props.interactiveData, variable: e.target.value })}
                            multiline
                            size="small"
                            fullWidth
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={1} sx={{ alignContent: 'center' }}>
                        <div style={{ width: 'min-content' }}>
                            <Tooltip title={t('interactive.tooltip_variable_name')} arrow>
                                <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', mr: 1, cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={11}>
                        <TextField
                            label={t('interactive.validation_attempts')}
                            id="validation_attempts"
                            value={props.interactiveData.validation_attempts ?? ''}
                            onChange={(e): void => props.setInteractiveData({ ...props.interactiveData, validation_attempts: e.target.value })}
                            type='number'
                            size="small"
                            fullWidth
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={1} sx={{ alignContent: 'center' }}>
                        <div style={{ width: 'min-content' }}>
                            <Tooltip title={t('interactive.tooltip_validation_attempts')} arrow>
                                <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', mr: 1, cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={11}>
                        <TextField
                            label={t('interactive.validation')}
                            id="validation"
                            value={props.interactiveData.validation ?? ''}
                            onChange={(e): void => props.setInteractiveData({ ...props.interactiveData, validation: e.target.value })}
                            multiline
                            size="small"
                            fullWidth
                            sx={{ m: 1 }}
                        />
                    </Grid>
                    <Grid item xs={1} sx={{ alignContent: 'center' }}>
                        <div style={{ width: 'min-content' }}>
                            <Tooltip title={t('interactive.tooltip_validation')} arrow>
                                <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', mr: 1, cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={0} className='interactive-container'>
                <Grid item xs={11}>
                    <Typography
                        textAlign='left'
                        variant='h6'
                        sx={{ ml: 1, fontSize: '1.2rem' }}
                    >
                        {t('interactive.inactivity_options')}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Box >
                        <Switch
                            size="small"
                            checked={props.enableInactivityOptions}
                            color='primary'
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            onChange={(): void => props.setEnableInactivityOptions(!props.enableInactivityOptions)}
                            sx={{ ml: 1.5 }}
                        />
                    </Box>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={11}>
                        <TextField
                            label={t('interactive.chat_timeout')}
                            id="chat-timeout"
                            type='number'
                            value={props.interactiveData.inactivity_options?.chat_timeout ?? ''}
                            onChange={(e): void => props.setInteractiveData(
                                {
                                    ...props.interactiveData,
                                    inactivity_options:
                                        { ...props.interactiveData.inactivity_options, chat_timeout: e.target.value },
                                }
                            )}
                            sx={{ mt: 1, mb: 1, ml: 1 }}
                            disabled={!props.enableInactivityOptions}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sx={{ alignContent: 'center' }}>
                        <div style={{ width: 'min-content' }}>
                            <Tooltip title={t('interactive.tooltip_chat_timeout')} arrow>
                                <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', mr: 1, cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={6}>
                    <Grid item xs={10}>
                        <TextField
                            label={t('interactive.attempts')}
                            id="attempts"
                            type='number'
                            value={props.interactiveData.inactivity_options?.attempts ?? ''}
                            onChange={(e): void => props.setInteractiveData({
                                ...props.interactiveData,
                                inactivity_options: {
                                    ...props.interactiveData.inactivity_options, attempts: e.target.value,
                                },
                            })}
                            sx={{ mt: 1, ml: 1, mb: 1, width: '100%'}}
                            fullWidth
                            disabled={!props.enableInactivityOptions}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={1} sx={{ alignContent: 'center' }}>
                        <div style={{ width: 'min-content' }}>
                            <Tooltip title={t('interactive.tooltip_attempts')} arrow>
                                <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', cursor: 'pointer', ml: 2 }} />
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={6}>
                    <Grid item xs={10}>
                        <TextField
                            label={t('interactive.time_between_attempts')}
                            id="time-between-attempts"
                            type='number'
                            value={props.interactiveData.inactivity_options?.time_between_attempts ?? ''}
                            onChange={(e): void => props.setInteractiveData({
                                ...props.interactiveData,
                                inactivity_options: {
                                    ...props.interactiveData.inactivity_options, time_between_attempts: e.target.value,
                                },
                            })}
                            sx={{ mt: 1, ml: 1, width: '100%' }}
                            disabled={!props.enableInactivityOptions}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={1} sx={{ alignContent: 'center' }}>
                        <div style={{ width: 'min-content' }}>
                            <Tooltip title={t('interactive.tooltip_time_between_attempts')} arrow>
                                <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', cursor: 'pointer', ml: 2 }} />
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={10}>
                        <TextField
                            label={t('interactive.warning_message')}
                            id="warning-message"
                            value={props.interactiveData.inactivity_options?.warning_message ?? ''}
                            onChange={(e): void => handleChangeOption(e.target.value)}
                            sx={{ mt: 1, ml: 1, mb: 1 }}
                            disabled={!props.enableInactivityOptions}
                            multiline
                            inputRef={getMessageInput}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={1} sx={{ alignContent: 'center' }}>
                        <EmojiPickerComponent
                            onEmojiSelect={(e:string) => addEmoji(e)}
                            align={alignButton.bottom}
                            disabled={!props.enableInactivityOptions}
                        />
                    </Grid>
                    <Grid item xs={1} sx={{ alignContent: 'center' }}>
                        <div style={{ width: 'min-content' }}>
                            <Tooltip title={t('interactive.tooltip_warning_message')} arrow>
                                <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', mr: 1, cursor: 'pointer' }} />
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={0} className='interactive-container'>
                <Typography variant='h6' gutterBottom sx={{ ml: 1, fontSize: '1.2rem' }}>
                    {t('interactive.interactive_message')}
                </Typography>
                <InteractiveFormMessage
                    interactiveData={props.interactiveData}
                    setInteractiveData={props.setInteractiveData}
                    interactiveTypeMedia={props.interactiveTypeMedia}
                    setInteractiveTypeMedia={props.setInteractiveTypeMedia}
                    interactiveOptionsValues={props.interactiveOptionsValues}
                    setInteractiveOptionsValues={props.setInteractiveOptionsValues}
                    bridgeOption={props.bridgeOption}
                    setBridgeOption={props.setBridgeOption}
                    interactiveTypeOption={props.interactiveTypeOption}
                    key={'interactive-form-message'}
                />
            </Grid>
            <Grid container spacing={0} className='interactive-container'>
                <Grid container spacing={0} sx={{ mb: 1 }}>
                    <Grid item xs={10} >
                        <Typography
                            textAlign="left"
                            variant='h6'
                            sx={{ ml: 1, fontSize: '1.2rem' }}
                        >
                            {t('interactive.cases')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Box className='add-case' textAlign={'end'}>
                            <Tooltip title={t('case_component.tooltip_add_case')}>
                                <IconButton
                                    aria-label="add-case"
                                    onClick={addNewCase}
                                    sx={{ ml: 4 }}
                                >
                                    <Add sx={{ fontSize: '1rem' }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item xs={1} justifySelf={'start'}>
                        <Box className='expand-case-option' textAlign={'start'}>
                            <IconButton
                                aria-label='expand-interactive-item'
                                onClick={() => { handleExpandClick(); }}
                                sx={{ ml: 2 }}
                            >
                                {expanded && <ExpandMore sx={{ fontSize: '1rem' }} />}
                                {!expanded && <ExpandLess sx={{ fontSize: '1rem' }} />}
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppableInteractiveCases">
                        {(provided: DroppableProvided) => (
                            <Grid
                                container
                                spacing={2}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {
                                    props.interactiveData.cases.map((item, index) => (
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{ ml: 2, mr: 2 }}
                                            key={`components-interactive-index-cases-${index}`}
                                        >
                                            <CaseComponent
                                                key={index}
                                                data={item}
                                                index={index}
                                                removeCase={removeCase}
                                                saveCase={saveCase}
                                                addVariable={addVariable}
                                                removeVariable={removeVariable}
                                                saveVariable={saveVariable}
                                                expanded={expanded}
                                                textFileWidth={'46.2%'}
                                            />
                                        </Grid>
                                    ))
                                }
                                {provided.placeholder}
                            </Grid>

                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>
            <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                {props.formErrorVisible &&
                    <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                        {props.formErrorMessage}
                    </Alert>
                }
            </Box>
        </>
    );
};

export default InteractiveForm;