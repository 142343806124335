import { Box, Grid, Typography } from '@mui/material';
import { InteractiveAction, InteractiveData, InteractiveItems, InteractiveOption } from '../../models/Interactive';
import { PanoramaFishEye } from '@mui/icons-material';

const PreviewListOptions = (
    props: {
        interactiveData: InteractiveData
        interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[]
    }
) => {

    return (
        <Grid container spacing={1} >
            {
                props.interactiveOptionsValues.map((option, index_option) => {
                    return (
                        <Grid item xs={12} key={`interactive-option-${index_option}`}>
                            <Box sx={{ mb: 1 }}>
                                <Typography
                                    textAlign='left'
                                    variant='h6'
                                    sx={{
                                        fontSize: '.9rem',
                                        color: '#444',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {(option as InteractiveItems).title}
                                </Typography>
                            </Box>
                            <Box>
                                {
                                    (option as InteractiveItems).options &&
                                    (option as InteractiveItems).options.map((item, index) => {
                                        return (
                                            <Box
                                                key={`list-item-option-${index}`}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    mb: 2,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'initial',
                                                        alignItems: 'center',
                                                        width: '90%',
                                                    }}
                                                >
                                                    <Typography
                                                        textAlign='left'
                                                        variant='body1'
                                                        sx={{
                                                            fontSize: '1rem',
                                                            color: '#444',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                    <Typography
                                                        textAlign='left'
                                                        variant='body1'
                                                        sx={{
                                                            fontSize: '.8rem',
                                                            color: '#444',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {item.description}
                                                    </Typography>
                                                </Box>
                                                <PanoramaFishEye
                                                    sx={{
                                                        fontSize: '1.2rem',
                                                        color: '#444',
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })
                                }
                            </Box>
                        </Grid>
                    );
                })
            }
        </Grid>
    );
};

export default PreviewListOptions;