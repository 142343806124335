import { ClassicPreset as Classic } from 'rete';
import { InputControl } from '../controls';

const socket = new Classic.Socket('socket');

export class StartNode extends Classic.Node {
    width = 200;
    height = 100;
    constructor() {
        super('Start');

        this.addOutput('o_connection', new Classic.Output(socket, 'o_connection', false));
        this.addControl(
            'node_id',
            new InputControl({ readonly: true, placeholder: 'ID', value: 'start' })
        );
    }
}