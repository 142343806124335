import { ClassicPreset as Classic } from 'rete';
import { InputControl } from '../controls';
import { StartSubData } from '../models/StartSub';
import { DiContainer } from '../types';
import { StartSubModalControl } from '../controls/';

const socket = new Classic.Socket('socket');

declare type StartSubNodeData = {
    node_id: string;
    startsub_data?: StartSubData;
};

export class StartSubNode extends Classic.Node {
    width = 200;
    height = 120;
    constructor(di: DiContainer, nodeData?: StartSubNodeData) {
        super('StartSub');

        this.addOutput('o_connection', new Classic.Output(socket, 'o_connection', false));
        this.addControl(
            'node_id',
            new InputControl({ hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id })
        );
        this.addControl(
            'modal',
            new StartSubModalControl(
                { di, nodeId: this.id, socket, modalInfo: nodeData?.startsub_data }
            )
        );
    }
}