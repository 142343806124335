import { ClassicPreset as Classic } from 'rete';
import { InputControl, HttpRequestModalControl } from '../controls';
import { DiContainer } from '../types';
import { HttpRequestData } from '../models/HttpRequest';

const socket = new Classic.Socket('socket');

declare type HttpRequestNodeData = {
    node_id: string;
    height?: number;
    nodeData?: HttpRequestData;
};

export class HttpRequestNode extends Classic.Node {
    width = 200;
    height = 180;
    constructor(di: DiContainer, nodeData?: HttpRequestNodeData) {
        super('HttpRequest');

        this.height = nodeData?.height || 180;
        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addOutput('default', new Classic.Output(socket, 'default', false));
        this.addControl(
            'node_id',
            new InputControl(
                { hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id }
            )
        );
        this.addControl(
            'modal',
            new HttpRequestModalControl(
                { di, nodeId: this.id, socket, modalInfo: nodeData?.nodeData }
            )
        );
    }
}