import { AccessTime, Add, CalendarMonth, DateRange, Remove, Today } from '@mui/icons-material';
import { Grid, IconButton, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { CheckTimeData, week_days, months, time, days_of_month, Range } from '../../../models/CheckTime';
import { useTranslation } from 'react-i18next';

declare type RangeSectionProps = {
    title: string;
    data: Range[];
    key_name: keyof CheckTimeData;
    add: (key: keyof CheckTimeData) => void;
    remove: (index: number, key: keyof CheckTimeData) => void;
    saveStart: (index: number, key: keyof CheckTimeData, value: string) => void;
    saveEnd: (index: number, key: keyof CheckTimeData, value: string) => void;
};

export const RangeSection = (props: RangeSectionProps): JSX.Element => {
    const { t } = useTranslation();

    const get_select_data = (): Array<{ value: string, label: string }> | undefined => {
        switch (props.key_name) {
        case 'time_ranges':
            return time;
        case 'days_of_week':
            return week_days;
        case 'days_of_month':
            return days_of_month;
        case 'months':
            return months;
        }
    };

    const getIconByKeyName = (key_name: string) => {
        switch (key_name) {
        case 'time_ranges':
            return <AccessTime />;
        case 'days_of_week':
            return <DateRange />;
        case 'days_of_month':
            return <CalendarMonth  />;
        case 'months':
            return <Today />;
        }
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const select_data = get_select_data();
    return (
        <div className='time_range_content'>
            <Grid container spacing={2}>
                <Grid item xs={11}>
                    <div className='time_range_title'>
                        <div className='icon_title_time'>{getIconByKeyName(props.key_name)}</div>
                        <div>{props.title} *</div>
                    </div>
                </Grid>
                <Grid item xs={1}>
                    <div className='float-right box-button-style'>
                        <IconButton
                            size="small"
                            aria-label='add-time-range'
                            onClick={(): void => props.add(props.key_name)}
                            sx={{ mt: 1 }}
                        >
                            <Add sx={{ fontSize: '1rem'  }}/>
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            <div>
                {props.data.map((item, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center'  }}>
                        <FormControl sx={{ m: 1, width: '20ch' }}>
                            <InputLabel size="small" id='input-type-label'>{t('start')}</InputLabel>
                            <Select
                                labelId='start-label'
                                id='start'
                                value={item.start}
                                required
                                onChange={(e): void => { props.saveStart(index, props.key_name, e.target.value); }}
                                label='Start'
                                MenuProps={MenuProps}
                                size="small"
                            >
                                {select_data!.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>{t(option.label)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, width: '20ch' }}>
                            <InputLabel size="small" id='input-type-label'>{t('end')}</InputLabel>
                            <Select
                                labelId='end-label'
                                id='end'
                                value={item.end}
                                onChange={(e): void => { props.saveEnd(index, props.key_name, e.target.value); }}
                                label='End'
                                MenuProps={MenuProps}
                                disabled={props.data[index].start === '*' ? true : false}
                                size="small"
                            >
                                {select_data!.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>{t(option.label)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box className={'box-button-style'} >
                            <IconButton
                                aria-label='remove-range'
                                sx={{ m: 1 }}
                                size="small"
                                onClick={(): void => props.remove(index, props.key_name)}>
                                <Remove sx={{ fontSize: '1rem'  }}/>
                            </IconButton>
                        </Box>
                    </Box>
                ))}
            </div>
        </div>
    );
};