import { ClassicScheme, RenderEmit, Presets } from 'rete-react-plugin';
import styled, { css } from 'styled-components';
import { $nodeheight, $nodewidth, $socketmargin, $socketsize } from '../../components/utils/vars';
import { RadioButtonChecked } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { checkJinja2Syntaxis } from '../../../util/util';
import { StartSubModalControl } from '../../controls';

const { RefSocket, RefControl } = Presets.classic;

type NodeExtraData = { width?: number; height?: number };

export const NodeStyles = styled.div<
    NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  background: rgb(0 84 110 / 63%) !important;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  width: ${(props) =>
        Number.isFinite(props.width) ? `${props.width}px` : `${$nodewidth}px`};
  height: ${(props) =>
        Number.isFinite(props.height) ? `${props.height}px` : `${$nodeheight}px`};
  padding-bottom: 6px;
  position: relative;
  user-select: none;
  &:hover {
    background: rgb(0 84 110 / 82%) !important;
  }
  ${(props) =>
        props.selected &&
        css`
      border-color: black !important;
      background: rgb(0 84 110 / 100%) !important;
    `}
  .title {
    color: white;
    font-family: sans-serif;
    font-size: 18px;
    padding: 8px;
    display: flex;
    align-items: center;
  }
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    text-align: right;
    margin-right: -16px;
    display: inline-block;
  }
  .input-socket {
    text-align: left;
    margin-left: -16px;
    display: inline-block;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: white;
    display: inline-block;
    font-family: sans-serif;
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .control {
    position: absolute;
    bottom: 0px;
    left: -11px;
    padding: 6px 12px;
    display: contents;
  }
  .label-name{
    margin-left: 10px;
  }
  ${(props) => props.styles && props.styles(props)}
`;

function sortByIndex<T extends [string, undefined | { index?: number }][]>(
    entries: T
) {
    entries.sort((a, b) => {
        const ai = a[1]?.index || 0;
        const bi = b[1]?.index || 0;

        return ai - bi;
    });
}

type Props<S extends ClassicScheme> = {
    data: S['Node'] & NodeExtraData;
    styles?: () => any;
    emit: RenderEmit<S>;
};
export type NodeComponent<Scheme extends ClassicScheme> = (
    props: Props<Scheme>
) => JSX.Element;

export function CustomNodeStartSub<Scheme extends ClassicScheme>(props: Props<Scheme>) {
    const { t } = useTranslation();
    const inputs = Object.entries(props.data.inputs);
    const outputs = Object.entries(props.data.outputs);
    const controls = Object.entries(props.data.controls);
    const selected = props.data.selected || false;
    const { id, label, width, height } = props.data;
    const nodeName = (props.data.controls?.modal as StartSubModalControl).options?.modalInfo?.name ?? t(`nodes.${label}`);

    sortByIndex(inputs);
    sortByIndex(outputs);
    sortByIndex(controls);

    return (
        <NodeStyles
            selected={selected}
            width={width}
            height={height}
            styles={props.styles}
            data-testid='node'
        >
            <div
                onPointerDown={(e) => {
                    e.stopPropagation();
                }}
                className='title'
                data-testid='title'
            >
                <RadioButtonChecked fontSize='medium' />
                <div className='label-name'>{nodeName}</div>
            </div>
            {/* Outputs */}
            {outputs.map(
                ([key, output]) =>
                    output && (
                        <div className='output' key={key} data-testid={`output-${key}`}>
                            <div className='output-title' data-testid='output-title'>
                                {checkJinja2Syntaxis(output?.label ?? '')? t('conditional') : t(output?.label ?? '')}
                            </div>
                            <RefSocket
                                name='output-socket'
                                side='output'
                                emit={props.emit}
                                socketKey={key}
                                nodeId={id}
                                payload={output.socket}
                            />
                        </div>
                    )
            )}
            {/* Controls */}
            {controls.map(([key, control]) => {
                return control ? (
                    <RefControl
                        key={key}
                        name='control'
                        emit={props.emit}
                        payload={control}
                    />
                ) : null;
            })}
            {/* Inputs */}
            {inputs.map(
                ([key, input]) =>
                    input && (
                        <div className='input' key={key} data-testid={`input-${key}`}>
                            <RefSocket
                                name='input-socket'
                                emit={props.emit}
                                side='input'
                                socketKey={key}
                                nodeId={id}
                                payload={input.socket}
                            />
                            {input && (!input.control || !input.showControl) && (
                                <div className='input-title' data-testid='input-title'>
                                    {t(`nodes.${input?.label}`)}
                                </div>
                            )}
                            {input?.control && input?.showControl && (
                                <span className='input-control'>
                                    <RefControl
                                        key={key}
                                        name='input-control'
                                        emit={props.emit}
                                        payload={input.control}
                                    />
                                </span>
                            )}
                        </div>
                    )
            )}
        </NodeStyles>
    );
}