import { ClassicPreset as Classic } from 'rete';
import { InputControl } from '../controls';
import { DiContainer } from '../types';
import { SubroutineData } from '../models/Subroutine';
import { SubroutineModalControl } from '../controls/modal/subroutine_modal/SubroutineModal';

declare type SubroutineNodeData = {
    node_id: string;
    subroutine_data: SubroutineData;
};

const socket = new Classic.Socket('socket');
export class SubroutineNode extends Classic.Node {
    width = 200;
    height = 180;
    constructor(di: DiContainer, nodeData?: SubroutineNodeData) {
        super('Subroutine');

        this.addInput('input', new Classic.Input(socket, 'input', true));
        this.addOutput('o_connection', new Classic.Output(socket, 'o_connection', false));
        this.addControl(
            'node_id',
            new InputControl(
                { hidden: true, placeholder: 'ID', value: nodeData?.node_id ? nodeData?.node_id : this.id }
            )
        );
        this.addControl(
            'modal',
            new SubroutineModalControl(
                { di, nodeId: this.id, socket, modalInfo: nodeData?.subroutine_data }
            )
        );
    }
}