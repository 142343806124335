/*
    This file contains the configuration of the toolbar editor.
*/
export const byMessage = {
    options: ['inline', 'list', 'history'],
    inline: { inDropdown: false, options: ['bold', 'italic'] },
    list: { inDropdown: false, options: ['unordered', 'ordered'] },
    history: { inDropdown: false },
};

export const byEmail = {
    options: ['inline', 'list', 'fontSize', 'fontFamily', 'textAlign', 'image', 'link', 'history'],
    inline: { inDropdown: false, options: ['bold', 'italic'] },
    list: { inDropdown: false, options: ['unordered', 'ordered'] },
    history: { inDropdown: false },

};