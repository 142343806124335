import React, { useState } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { IconButton } from '@mui/material';
import { EmojiEmotions } from '@mui/icons-material';

export enum alignButton {
    baseline = 'baseline',
    top = 'top',
    bottom = 'bottom',
    middle = 'middle',
    textTop = 'text-top',
    sub = 'sub',
    super = 'super',
}

interface EmojiPickerComponentProps {
    onEmojiSelect: (emoji: string) => void;
    align: string;
    disabled?: boolean;
}

const EmojiPickerComponent: React.FC<EmojiPickerComponentProps> = ({ onEmojiSelect, align, disabled }) => {
    const [isEmojiPickerVisible, setEmojiPickerVisible] = useState(false);
    const [pickerPosition, setPickerPosition] = useState({ top: 0, left: 0 });

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPickerPosition({ top: event.clientY, left: event.clientX });
        setEmojiPickerVisible(!isEmojiPickerVisible);
    };

    return (
        <div style={{ display: 'inline', verticalAlign: align }}>
            <IconButton
                key="emoji-picker-icon-button"
                aria-label="emoji-picker"
                size="medium"
                onClick={handleButtonClick}
                sx={{ height: 'min-content', alignSelf: 'center' }}
                disabled={disabled}
            >
                <EmojiEmotions fontSize="inherit" />
            </IconButton>
            {isEmojiPickerVisible && (
                <div style={{ position: 'fixed', zIndex: 100, top: pickerPosition.top, left: pickerPosition.left }}>
                    <EmojiPicker
                        key={`${Math.random().toString(36).substring(7)}-emoji-picker-custom`}
                        onEmojiClick={(emojiObject: { emoji: string }) => {
                            onEmojiSelect(emojiObject.emoji);
                            setEmojiPickerVisible(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default EmojiPickerComponent;