import { useState } from 'react';
import { useRete } from 'rete-react-plugin';
import './App.css';
import './rete.css';
import { createEditor as editor } from './rete/editor';
import { ExportButton, FlowVariables } from './components';
import { Alert, Box, Typography } from '@mui/material';

function App(): JSX.Element {
    const [ref, editorProps] = useRete(editor);
    const [showAlert, setshowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const showMainAlert = (message: string): void => {
        setAlertMessage(message);
        setshowAlert(true);
        setTimeout(() => setshowAlert(false), 3000);
    };

    return (
        <div className='editor'>
            <div className='container-editor'>
                {showAlert &&
                    <Alert severity='success' className='mt-16 mb-16 ml-16 main-alert'>
                        <Typography textAlign={'center'} variant='body1' gutterBottom>
                            {alertMessage}
                        </Typography>
                    </Alert>
                }
                <Box className={'export-button'}>
                    <ExportButton editor={editorProps?.editor} showMainAlert={showMainAlert} />
                </Box>
                <Box className={'flow-variables-button'}>
                    <FlowVariables showMainAlert={showMainAlert} />
                </Box>
                <div ref={ref} className="rete"></div>
                <div id="modal-root"></div>
            </div>
        </div>
    );
}

export default App;
