
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    TextField,
    Typography,
    Button,
    Alert,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListSubheader,
    MenuItem,
    InputLabel,
    Select,
    FormControl,
} from '@mui/material';
import { WarningAmber, Settings, Cancel, Save } from '@mui/icons-material';
import { DiContainer } from '../../../types';
import { InviteUserData } from '../../../models/InviteUser';
import { useTranslation } from 'react-i18next';
import { checkJinja2Syntaxis, getNextNodeName, mainParams, nodeNameAlreadyExists } from '../../../../util/util';
import { AcdService } from '../../../../services';
import { StaticCaseVariables } from '../../../components/StaticCaseVariables';

type DestinationSelect = {
    label: string;
    value: string;
};

declare type InviteuserModalControlOptions = {
    modalInfo?: InviteUserData;
    nodeId: string;
    di: DiContainer;
    socket: Classic.Socket;
};

export class InviteUserModalControl extends Classic.Control {
    constructor(public options?: InviteuserModalControlOptions) {
        super();
    }
}

export const InviteUserModal = (props: { data: InviteUserModalControl }): JSX.Element => {
    const { t } = useTranslation();
    const [inviteUserData, setInviteUserData] = useState<InviteUserData>({
        name: getNextNodeName('Invite User', props.data.options!.di.editor.getNodes()),
        timeout: '',
        invitee: '',
        cases: [
            {'id': 'join', 'o_connection': '', 'variables': []},
            {'id': 'reject', 'o_connection': '', 'variables': []},
            {'id': 'timeout', 'o_connection': '', 'variables': []},
        ],
    });
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [agents, setAgents] = useState<DestinationSelect[]>([]);
    const [menus, setMenus] = useState<DestinationSelect[]>([]);
    const [showCustomInvitee, setShowCustomInvitee] = useState(false);
    const [customInvitee, setCustomInvitee] = useState('');
    const userNameRegex = new RegExp('^@[a-z]+[0-9]*:[a-z]+.[a-z]+(.[a-z]+)?$');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const [menuflowDomain, botMxid] = mainParams();
        const acdService = new AcdService(menuflowDomain, botMxid);

        const fetchDestinationData = async (): Promise<void> => {
            const [menus, menuStatus] = await acdService.getUsers('MENU');
            const [agents, AgentStatus] = await acdService.getUsers('AGENT');

            if (menuStatus !== 200 || AgentStatus !== 200) {
                showErrorMessage(t('invite_user.failed_to_fetch_destination_data'));
            }

            const menusMap = menus.users.map((menu) => {
                return {
                    label: menu.displayname,
                    value: menu.mxid,
                };
            });
            setMenus(menusMap);

            const agentsMap = agents.users.map((agent) => {
                return {
                    label: agent.displayname,
                    value: agent.mxid,
                };
            });
            setAgents(agentsMap);
        };

        fetchDestinationData();
    }, []);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useMemo (() => {
        if (props.data.options?.modalInfo && !open) {
            const node_data_copy = JSON.parse(JSON.stringify(props.data.options.modalInfo));
            if (checkJinja2Syntaxis(node_data_copy.invitee)) {
                setCustomInvitee(node_data_copy.invitee);
                node_data_copy.invitee = 'custom';
                setShowCustomInvitee(true);
            }else{
                setCustomInvitee('');
                setShowCustomInvitee(false);
            };
            setInviteUserData(node_data_copy);
        }
    }, [open]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const validateForm = (): string | undefined => {
        const invitee = inviteUserData.invitee === 'custom' ? customInvitee : inviteUserData.invitee;
        if (inviteUserData.invitee === 'custom' && customInvitee === '') {
            return t('invite_user.custom_invitee_field_empty');
        }else if (invitee.match(userNameRegex) === null && !checkJinja2Syntaxis(invitee)) {
            return t('invite_user.invitee_must_be_a_valid_user_mxid_or_variable');
        }else if (inviteUserData.name.length > 20) {
            return t('invite_user.name_field_too_long');
        } else if (nodeNameAlreadyExists(inviteUserData.name, props.data.options?.nodeId, props.data.options?.di.editor.getNodes())) {
            return t('invite_user.name_already_exists');
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const error = validateForm();
        if (error) {
            showErrorMessage(error);
            return;
        }

        props.data.options!.modalInfo = {
            name: inviteUserData.name,
            timeout: inviteUserData.timeout,
            invitee: inviteUserData.invitee === 'custom' ? customInvitee : inviteUserData.invitee,
            cases: inviteUserData.cases,
        };
        props.data.options!.di.updateNode(props.data.options!.nodeId);
        onClose();
    };

    const renderDestinationOptions = (): JSX.Element[] => {
        const destinationOptions: JSX.Element[] = [];
        destinationOptions.push(<ListSubheader key="empty-header">{t('invite_user.custom')}</ListSubheader>);
        destinationOptions.push(<MenuItem key="custom" value="custom">{t('invite_user.custom')}</MenuItem>);

        destinationOptions.push(<ListSubheader key="menu-header">{t('invite_user.menus')}</ListSubheader>);
        menus.map((destination) => (
            destinationOptions.push(
                <MenuItem key={destination.value} value={destination.value}>
                    {destination.label !== null ? destination.label : destination.value}
                </MenuItem>
            )
        ));

        destinationOptions.push(<ListSubheader key="agents-header">{t('invite_user.agents')}</ListSubheader>);
        agents.map((destination) => (
            destinationOptions.push(
                <MenuItem key={destination.value} value={destination.value}>
                    {destination.label !== null ? destination.label : destination.value}
                </MenuItem>
            )
        ));

        return destinationOptions;
    };

    const addVariable = (caseIndex: number): void => {
        const newCases = [...inviteUserData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.push({ key: '', value: '' });
        }
        setInviteUserData({ ...inviteUserData, cases: newCases });
    };

    const saveVariable = (index: number, caseIndex: number, key: 'key' | 'value', value: string): void => {
        const newCases = [...inviteUserData.cases];
        if (newCases[caseIndex].variables != undefined) {
            (newCases[caseIndex].variables ?? [])[index][key] = value;
        }
        setInviteUserData({ ...inviteUserData, cases: newCases });
    };

    const removeVariable = (caseIndex: number, index: number): void => {
        const newCases = [...inviteUserData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.splice(index, 1);
        }
        setInviteUserData({ ...inviteUserData, cases: newCases });
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    <Typography textAlign={'center'} variant='h6' gutterBottom>
                        {t('invite_user.invite_user_node_options')}
                        <span className='close' onClick={onClose}>
                            &times;
                        </span>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <TextField
                            label={t('invite_user.name')}
                            id='node-name'
                            value={inviteUserData.name}
                            required
                            onChange={(e): void => setInviteUserData({ ...inviteUserData, name: e.target.value })}
                            sx={{ m: 1, width: '98.5%' }}
                            size="small"
                            autoFocus
                        />
                        <FormControl sx={{ m: 1, width: '47%' }} size="small">
                            <InputLabel id='input-type-label'>{t('invite_user.invitee')}</InputLabel>
                            <Select
                                labelId='destination-label'
                                id='destination'
                                value={inviteUserData.invitee}
                                required
                                onChange={(e): void => {
                                    const value = e.target.value;
                                    setInviteUserData({ ...inviteUserData, invitee: value });
                                    value === 'custom' ? setShowCustomInvitee(true) : setShowCustomInvitee(false);
                                }}
                                label={t('invite_user.destination')}
                                autoFocus
                            >
                                {renderDestinationOptions()}
                            </Select>
                        </FormControl>
                        <TextField
                            label={t('invite_user.timeout')}
                            id="timeout"
                            fullWidth
                            value={inviteUserData.timeout}
                            onChange={(e): void => {
                                if (parseInt(e.target.value,10) < 0) {
                                    e.target.value = '0';
                                    showErrorMessage('The number cannot be less than 0');
                                }
                                setInviteUserData({ ...inviteUserData, timeout: e.target.value });
                            }}
                            sx={{ m: 1, width: '47%' }}
                            required
                            size="small"
                            type='number'
                        />
                        {showCustomInvitee &&
                            <TextField
                                label={t('invite_user.custom_invitee')}
                                id='node-custom-invitee'
                                value={customInvitee}
                                multiline
                                onChange={(e): void => setCustomInvitee(e.target.value )}
                                sx={{ m: 1, width: '98.5%' }}
                                size="small"
                            />
                        }
                        <Typography textAlign='left' variant='subtitle1' sx={{ mb: 2 }}>{t('invite_user.cases')}</Typography>
                        {inviteUserData.cases.map((item, index) => (
                            <StaticCaseVariables
                                key={index}
                                index={index}
                                data={item}
                                saveVariable={saveVariable}
                                removeVariable={removeVariable}
                                addVariable={addVariable}
                            />
                        ))}
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('invite_user.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('invite_user.save_changes')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};