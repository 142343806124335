import { ExportCase, Case } from './Switch';

export type Range = {
    start: string;
    end?: string;
}

export const toRange = (day: string): Range => {
    if (day === '*') {
        return { start: '*' };
    } else {
        const [start, end] = day.split('-');
        return { start, end };
    }
};

export declare type CheckTimeData = {
    name: string
    timezone: string;
    time_ranges?: Range[];
    days_of_week?: Range[];
    days_of_month?: Range[];
    months?: Range[];
    cases: Case[];
};

export type ExportCheckTimeNode = {
    id: string;
    type: string;
    name: string;
    timezone: string;
    time_ranges: string[];
    days_of_week: string[];
    days_of_month: string[];
    months: string[];
    cases: ExportCase[];
};

export const week_days = [
    { 'value': '*', 'label': '*' },
    { 'value': 'mon', 'label': 'monday' },
    { 'value': 'tue', 'label': 'tuesday' },
    { 'value': 'wed', 'label': 'wednesday' },
    { 'value': 'thu', 'label': 'thursday' },
    { 'value': 'fri', 'label': 'friday' },
    { 'value': 'sat', 'label': 'saturday' },
    { 'value': 'sun', 'label': 'sunday' },
];

export const months = [
    { 'value': '*', 'label': '*' },
    { 'value': 'jan', 'label': 'january' },
    { 'value': 'feb', 'label': 'february' },
    { 'value': 'mar', 'label': 'march' },
    { 'value': 'apr', 'label': 'april' },
    { 'value': 'may', 'label': 'may' },
    { 'value': 'jun', 'label': 'june' },
    { 'value': 'jul', 'label': 'july' },
    { 'value': 'aug', 'label': 'august' },
    { 'value': 'sep', 'label': 'september' },
    { 'value': 'oct', 'label': 'october' },
    { 'value': 'nov', 'label': 'november' },
    { 'value': 'dec', 'label': 'december' },
];

export const time = [
    { 'value': '*', 'label': '*' },
    { 'value': '00:00', 'label': '12:00 AM' },
    { 'value': '01:00', 'label': '1:00 AM' },
    { 'value': '02:00', 'label': '2:00 AM' },
    { 'value': '03:00', 'label': '3:00 AM' },
    { 'value': '04:00', 'label': '4:00 AM' },
    { 'value': '05:00', 'label': '5:00 AM' },
    { 'value': '06:00', 'label': '6:00 AM' },
    { 'value': '07:00', 'label': '7:00 AM' },
    { 'value': '08:00', 'label': '8:00 AM' },
    { 'value': '09:00', 'label': '9:00 AM' },
    { 'value': '10:00', 'label': '10:00 AM' },
    { 'value': '11:00', 'label': '11:00 AM' },
    { 'value': '12:00', 'label': '12:00 PM' },
    { 'value': '13:00', 'label': '1:00 PM' },
    { 'value': '14:00', 'label': '2:00 PM' },
    { 'value': '15:00', 'label': '3:00 PM' },
    { 'value': '16:00', 'label': '4:00 PM' },
    { 'value': '17:00', 'label': '5:00 PM' },
    { 'value': '18:00', 'label': '6:00 PM' },
    { 'value': '19:00', 'label': '7:00 PM' },
    { 'value': '20:00', 'label': '8:00 PM' },
    { 'value': '21:00', 'label': '9:00 PM' },
    { 'value': '22:00', 'label': '10:00 PM' },
    { 'value': '23:00', 'label': '11:00 PM' },
];

export const days_of_month = [
    { 'value': '*', 'label': '*' },
    { 'value': '1', 'label': '1st' },
    { 'value': '2', 'label': '2nd' },
    { 'value': '3', 'label': '3rd' },
    { 'value': '4', 'label': '4th' },
    { 'value': '5', 'label': '5th' },
    { 'value': '6', 'label': '6th' },
    { 'value': '7', 'label': '7th' },
    { 'value': '8', 'label': '8th' },
    { 'value': '9', 'label': '9th' },
    { 'value': '10', 'label': '10th' },
    { 'value': '11', 'label': '11th' },
    { 'value': '12', 'label': '12th' },
    { 'value': '13', 'label': '13th' },
    { 'value': '14', 'label': '14th' },
    { 'value': '15', 'label': '15th' },
    { 'value': '16', 'label': '16th' },
    { 'value': '17', 'label': '17th' },
    { 'value': '18', 'label': '18th' },
    { 'value': '19', 'label': '19th' },
    { 'value': '20', 'label': '20th' },
    { 'value': '21', 'label': '21st' },
    { 'value': '22', 'label': '22nd' },
    { 'value': '23', 'label': '23rd' },
    { 'value': '24', 'label': '24th' },
    { 'value': '25', 'label': '25th' },
    { 'value': '26', 'label': '26th' },
    { 'value': '27', 'label': '27th' },
    { 'value': '28', 'label': '28th' },
    { 'value': '29', 'label': '29th' },
    { 'value': '30', 'label': '30th' },
    { 'value': '31', 'label': '31st' },
];